import React from 'react';
import { useState } from 'react';
import { Link } from 'react-scroll';


const Nav = () => {
    const [navbarblur, setnavbarblur]=useState(false);

    function scrollHandler() {
        if (window.scrollY >= 20) {
            setnavbarblur(true);
        } 
        else {
            setnavbarblur(false);
        }
    }

    var showMenu= ()=>{
        var bar=document.getElementsByClassName("bar");
        var ham=document.getElementsByClassName("NavbarLinks");
        bar[0].classList.toggle("barOne");
        bar[1].classList.toggle("barTwo");
        bar[2].classList.toggle("barThree");

        ham[0].classList.toggle("showNavbar");
    }

    var hideMenu = ()=>{
        var bar=document.getElementsByClassName("bar");
        var ham=document.getElementsByClassName("NavbarLinks");
        bar[0].classList.remove("barOne");
        bar[1].classList.remove("barTwo");
        bar[2].classList.remove("barThree");
        ham[0].classList.remove("showNavbar");
    }
    
    window.addEventListener("scroll", scrollHandler);

  return (
    <nav className={navbarblur? 'Navbar blur':'Navbar'}> 
 
        <h1 className='Logo'>
            <a href="/" onClick={() => window.location.reload(true)}>Alexus Brooklyn</a>
        </h1>

        <div className='Hamburger' onClick={showMenu}>
            <span className='bar'></span>
            <span className='bar'></span>
            <span className='bar'></span>
        </div>

        <ul className='NavbarLinks'>
            <li onClick={hideMenu}><Link to="about" smooth={true} duration={100}>About</Link></li>
            <li onClick={hideMenu}><Link to="education" smooth={true} duration={100}>Education</Link></li>
            <li onClick={hideMenu}><Link to="projects" smooth={true} duration={100}>Projects</Link></li>
            <li onClick={hideMenu}><Link to="experience" smooth={true} duration={100}>Experience</Link></li>
            <li onClick={hideMenu}><Link to="skills" smooth={true} duration={100}>Skills</Link></li>
        </ul>
        
    </nav>
  )
}

export default Nav